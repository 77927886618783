export const COMMON = {
  namespaced: true,
  state: {
    post: {},
    isMobile: false,
  },
  mutations: {
    SET_POST(state, post) {
      state.post = post;
    },
  },
};
