<template>
  <div class="main-notice">
    <div class="title-wrap">
        <p class="line-txt">CONTACT US</p>
        <h1>0504-0804-6931</h1>
        <p class="sub-txt">나보로스는 언제나 고객과 소통할 준비가 되어 있습니다.</p>
      </div>
      <div :class="{ bounce: bounceEffect }" class="notice-contents">
        <h2>NAVOROS News</h2>
        <ul class="notice">
            <li v-for="(item, index) in noticeData" :key="index">
                <div class="date-wrap">
                    <p class="day">{{ trncateDate(item.insertTime) }}</p>
                    <p class="year-month">{{ trncateMonth(item.insertTime) }}</p>
                </div>
                <div class="content-wrap">
                    <router-link :to="`/notice/${item.id}`" class="title">{{ item.title }}</router-link>
                    <!-- <p class="title">{{ item.title }}</p> -->
                    <p class="content">{{ extractFirstLine(item.content) }}</p>
                </div>
                <div v-if="item.imgLocation !== null" class="img-wrap">
                    <img :src="item.imgLocation" alt="">
                </div>
            </li>
        </ul>
      </div>
      <div class="line-wrap">
        <div v-for="n in 5" :key="n" class="line"></div>
      </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            scrollPosition: 0,
            bounceEffect: false,
            hasBounced: false, // 튀는 효과가 한 번만 발생하도록 하기 위한 플래그
            noticeData: [],
        }
    },
    computed: {
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        this.getPostMain();
    },
    methods: {
        trncateDate(val) {
            return val.substr(8, 2);
        },
        trncateMonth(val) {
            return val.substr(0, 4) + '.' + val.substr(5, 2);
        },
        // 포트폴리오 및 공지사항 가져오기
        getPostMain() {
            this.$server.get('/getPostMain')
                .then(res => {
                    // console.log(res.data);
                    this.noticeData = res.data.noticeList;
                    // TODO: 포트폴리오 연결 해야힘
                })
                .catch(err => {
                    console.log(err);
                });
        },
        // content에서 첫 번째 줄의 텍스트만 추출
        extractFirstLine(content) {
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = content;
            // 첫 번째 <p> 태그를 선택하고 그 텍스트만 추출
            const firstP = tempDiv.querySelector("p");
            return firstP ? firstP.textContent.trim() : '';
        },
        handleScroll() {
            this.scrollPosition = window.scrollY;
            const triggerScroll = 3300;

            if (this.scrollPosition >= triggerScroll && !this.hasBounced) {
                this.bounceEffect = true;
                this.hasBounced = true;

                // 애니메이션이 끝난 후 클래스 제거
                setTimeout(() => {
                this.bounceEffect = false;
                }, 1000); // 애니메이션 지속시간과 동일하게 설정
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
}
</script>

<style scoped>
    .main-notice {
        position: relative;
        width: 100%;
        height: 870px;
        background-color: #000;
        padding-top: 120px;
        z-index: 2;
        overflow: hidden;
    }
    .title-wrap {
        color: #fff;
        text-align: center;
    }
    .title-wrap .line-txt {
        display: inline-block;
        font-size: 24px;
        font-weight: lighter;
        line-height: 34px;
        border-bottom: 1px solid #fff;
        letter-spacing: 5px;
    }
    .title-wrap h1 {
        font-size: 60px;
        margin-top: 20px;
        margin-bottom: 45px;
    }
    .title-wrap .sub-txt {
        font-size: 24px;
        /* line-height: 32px; */
        color: #777;
        /* font-weight: lighter; */
    }
    .notice-contents {
        position: absolute;
        box-sizing: border-box;
        width: 1200px;
        bottom: -50px;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: #fff;
        margin: 0 auto;
        margin-top: 100px;
        border-radius: 30px 30px 0 0;
        padding: 50px 170px;
        transition: transform 0.3s;
    }
    .bounce {
        animation: bounceUp 1s ease;
    }

    @keyframes bounceUp {
        0%, 100% {
            bottom: -50px;
        }
        50% {
            bottom: 0px;
        }
    }
    .notice-contents h2 {
        font-size: 36px;
        text-align: left;
        margin-bottom: 50px;
    }
    .notice-contents .notice {
        width: 100%;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        text-align: left;
        margin-bottom: 50px;
    }
    .notice li {
        /* outline: 1px solid green; */
        box-sizing: border-box;
        width: 100%;
        height: 140px;
        padding: 20px 0 20px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .notice li .date-wrap {
        /* outline: 1px solid red; */
        color: #999;
        text-align: center;
        margin-right: 55px;
    }
    .notice li .date-wrap .day {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .notice li .date-wrap .year-month {
        font-size: 14px;
    }
    .notice li .content-wrap {
        width: 100%;
        max-width: var(100% - 700px);
        position: relative;
        overflow: hidden;
        padding-right: 55px;
    }
    .notice li .content-wrap .title {
        display: block;
        font-weight: bold;
        margin-bottom: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #000;
        text-decoration: none;
    }
    .notice li .content-wrap .content {
        position: relative;
        width: 100%;
        font-size: 16px;
        color: #777;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .notice li .img-wrap {
        width: 150px;
        min-width: 150px;
        height: 100%;
        overflow: hidden;
        border-radius: 8px;
    }
    .notice li .img-wrap img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .line-wrap {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        z-index: -1;
    }
    .line {
        height: 100%;
        width: 1;
        border-right: 1px solid #fff;
        opacity: 0.2;
    }
    /* 태블릿 */
@media screen and (max-width: 1023px) {

}

/* 모바일 */
@media screen and (max-width: 767px) {
    .main-notice {
        padding-top: 80px;
        height: 530px;
    }
    .title-wrap .line-txt {
        font-size: 16px;
        line-height: 26px;
    }
    .title-wrap h1 {
    font-size: 36px;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .title-wrap .sub-txt {
    font-size: 16px;
    line-height: 22px;
    width: 80%;
    margin: 0 auto;
    word-break: keep-all;
  }
  .notice-contents {
    width: 90%;
    padding: 30px 20px;
    margin-top: 50px;
    border-radius: 20px 20px 0 0;
  }
  .notice-contents h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .notice li {
    /* outline: 1px solid red; */
    height: 70px;
    padding: 10px;
  }
  .notice li .content-wrap .title {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 6px;
    }
    .notice li .content-wrap .content {
        font-size: 13px;
        color: #777;
        line-height: 16px;
    }
  .notice li .date-wrap {
    margin-right: 20px;
  }
  .notice li .date-wrap .day {
    font-size: 28px;
  }
  .notice li .date-wrap .year-month {
        font-size: 12px;
    }
    .notice li .img-wrap {
        display: none;
    }

}
</style>