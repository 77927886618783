import Vue from "vue";
import VueRouter from "vue-router";
import MainView from "../views/MainView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    component: MainView,
  },
  {
    path: "/ceo", // ceo인사말
    name: "CEOSub",
    component: () => import("../views/CEOSub.vue"),
  },
  {
    path: "/company", // 회사소개
    name: "company",
    component: () => import("../views/CompanySub.vue"),
  },
  {
    path: "/contact", // 오시는 길
    name: "contact",
    component: () => import("../views/ContactSub.vue"),
  },
  {
    path: "/business", // 사업분야
    name: "business",
    component: () => import("../views/BusinessSub.vue"),
  },
  {
    path: "/notice", // 공지사항
    component: () => import("../views/NoticeBoard.vue"),
    children: [
      {
        path: "",
        name: "notice",
        component: () => import("../components/NoticeBoardList2.vue"),
      },
      {
        path: "write", // 공지사항 게시글 작성
        component: () => import("../components/NoticeWriteBoard.vue"),
      },
      {
        path: "edit/:id", // 공지사항 게시글 수정
        component: () => import("../components/NoticeEditBoard.vue"),
      },
      {
        path: ":id", // 공지사항 게시글
        component: () => import("../components/NoticePost.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/advice", //온라인견적
    component: () => import("../views/OnlineAdviceBoard.vue"),
    children: [
      {
        path: "", // 온라인견적 목록
        name: "AdviceBoard",
        component: () => import("../components/AdviceBoardList.vue"),
      },
      {
        path: "write", // 온라인 견적 게시글 작성
        component: () => import("../components/AdviceWriteBoard.vue"),
      },
      {
        path: "edit/:id", // 온라인견적 게시글 수정
        component: () => import("../components/AdviceEditBoard.vue"),
      },
      {
        path: ":id", // 온라인견적 게시글
        component: () => import("../components/AdvicePost.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/portfolio", // 포트폴리오
    name: "portfolio",
    component: () => import("../views/PortfolioBoard.vue"),
    children: [
      {
        path: "", // 포트폴리오 목록
        name: "PortfolioList",
        component: () => import("../components/PortfolioList.vue"),
      },
      {
        path: "write", // 포트폴리오 게시글 작성
        component: () => import("../components/PortfolioWrite.vue"),
      },
      {
        path: "edit/:id", // 포트폴리오 게시글 수정
        component: () => import("../components/PortfolioEditBoard.vue"),
      },
      {
        path: ":id", // 포트폴리오 게시글
        component: () => import("../components/PortfolioPost.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/login", // 관리자 로그인
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // 이전에 저장된 위치로 이동
    } else {
      return { x: 0, y: 0 }; // 페이지 맨 위로 이동
    }
  },
});

//로그인 권한이 없는 사람과 있는 사람을 구별해서 보여주는 부분 추가
//메뉴 이동할때 중간에 가로채는 이벤트
//1st 매개변수 to : 이동 할 페이지
//2nd 매개변수 from : 이동 전 페이지
//3rd 매개변수 next : 다음 화면으로 이동을 명령하는 함수
router.beforeEach((to, from, next) => {
  //권한 관리 : 회원, 비회원
  const user = JSON.parse(localStorage.getItem("navorosUser"));
  //인증 없이 접근 가능한 경로(화면)
  const publicPages = ["/notice/write", "/portfolio/write"];

  //인증이 있어야만 접근 가능한 경로
  const authRequired = publicPages.includes(to.path);

  if (authRequired && user.roles != "ROLE_ADMIN") {
    alert("접근 권한이 없습니다.");
    next("/");
  } else {
    next();
  }
});

export default router;
